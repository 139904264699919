// @flow

import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import {
  AboutFounder,
  Layout,
  LinkedButton,
  SectionScreenshot,
  MarkdownPs,
  Section,
  SectionsPartners,
  TextHeading,
} from '../components';
import { Dimen } from '../constants';
import { unwrapConnection } from '../util';

import type { PageAboutQuery } from '../__generated__/PageAboutQuery';

type Props = $ReadOnly<{|
  data: PageAboutQuery,
|}>;

const PageAbout = ({
  data: { page, allDatoCmsSectionScreenshot, allDatoCmsPartner },
}: Props) => {
  if (!page) throw new Error('About missing from CMS');
  if (!allDatoCmsPartner) throw new Error('Missing allDatoCmsPartner');

  const unwrappedScreenshotSections = unwrapConnection(
    allDatoCmsSectionScreenshot
  );
  const screenshotSections = {};
  [1, 2, 3].forEach(n => {
    const key = `about_${n}`;
    screenshotSections[key] = unwrappedScreenshotSections.find(
      b => b.identifier === key
    );
  });

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Section>
        <StyledLeadingText>
          <TextHeading level={1} tag="h1">
            {page.heading}
          </TextHeading>
          {page.leadingText && <MarkdownPs html={page.leadingText} />}
          <LinkedButton label={page.buttonLabel} link={page.buttonLink} />
        </StyledLeadingText>
      </Section>

      {(page.founders || []).filter(Boolean).map((founder, i) => (
        <AboutFounder
          key={founder.id}
          picAfter={i % 2 === 0}
          founder={founder}
          color={['#fed181', '#8fd0b8', '#24a9e1', '#ee4640'][i]}
        />
      ))}

      {screenshotSections.about_1 && (
        <SectionScreenshot
          screenshot={screenshotSections.about_1}
          imageSrc="/img/screenshots/about_1.png"
          effectSrc="/img/screenshots/effect_1.svg"
          picAfter
        />
      )}
      <SectionsPartners partnersConnection={allDatoCmsPartner} />
    </Layout>
  );
};
export default PageAbout;

export const query = graphql`
  query PageAboutQuery {
    page: datoCmsPageAbout {
      heading
      leadingText
      buttonLabel
      buttonLink
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      founders {
        id
        ...AboutFounder_founder
      }
    }

    allDatoCmsSectionScreenshot(
      filter: { identifier: { regex: "/^about_/" } }
    ) {
      edges {
        node {
          identifier
          ...SectionScreenshot_screenshot
        }
      }
    }

    allDatoCmsPartner {
      ...SectionsPartners_partnersConnection
    }
  }
`;

const StyledLeadingText = styled('div')`
  padding: ${rem(Dimen.spacing * 2)} 0;
  text-align: center;
`;
